/*!****************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./templates/rocket/submodules/galleries/galleries.scss ***!
  \****************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.gallery-list * {
  max-height: 300px;
}

.gallery-list {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
}
.gallery-list .gallery {
  border: none !important;
  border-radius: unset !important;
}
.gallery-list .gallery .card-body {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  align-items: center;
  justify-content: center;
  color: #fff;
  overflow: hidden;
}
.gallery-list .gallery .card-body .gallery-preview {
  display: flex;
  position: absolute;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0rem 1rem;
  cursor: pointer;
}
.gallery-list .gallery .card-body .gallery-preview .photo {
  height: auto;
  width: 100%;
  aspect-ratio: 1/1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 150px;
}
.gallery-list .gallery .card-body .gallery-image {
  height: auto;
  width: 100%;
  aspect-ratio: 1/1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 300px;
  cursor: pointer;
}
